'use client';

import type { ReactNode } from 'react';
import { useState } from 'react';

import { cn } from '@/lib/utils';

interface Props {
    children: ReactNode;
    readMoreText?: string;
    readLessText?: string;
    expandOnMobile?: boolean;
}

export default function ReadMore({
    children,
    readMoreText = 'Read more',
    readLessText = 'Read less',
    expandOnMobile,
}: Props) {
    const [collapsed, setCollapsed] = useState(true);

    const mobileCss = expandOnMobile
        ? 'max-sm:overflow-visible max-sm:h-auto'
        : undefined;

    const mobileCollapsedCss = expandOnMobile
        ? 'max-sm:h-auto max-sm:max-h-none max-sm:after:bg-inherit max-xl:after:static'
        : undefined;
    const collapsedCss = cn(
        'after:pointer-events-none max-xl:max-h-[24em] max-xl:after:absolute max-xl:after:bottom-0 max-xl:after:left-0 max-xl:after:right-0 max-xl:after:h-24 max-xl:after:bg-gradient-to-t max-xl:after:from-white max-xl:after:to-transparent',
        mobileCollapsedCss,
    );

    const mobileButtonCss = expandOnMobile ? 'max-sm:hidden' : undefined;

    return (
        <>
            <div
                className={cn(
                    'relative max-xl:overflow-hidden',
                    mobileCss,
                    collapsed ? collapsedCss : 'max-xl:h-auto',
                )}>
                {children}
            </div>
            <button
                className={cn(
                    'mt-4 underline underline-offset-2 xl:hidden',
                    mobileButtonCss,
                )}
                type="button"
                onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? readMoreText : readLessText}
            </button>
        </>
    );
}
